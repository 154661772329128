@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;500;700;900&display=swap');

.contain {
    padding: 2rem;
}


.hold {

    display: flex;
    text-align: center;
    flex-direction: column;
    width: 11rem;
    height: 13.5rem;
    border: none;
    border-radius:0 0 2rem 0;
    box-shadow: 1px 1px 10px 1px rgb(171, 170, 170);
}

.imgHold {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.img {
    width: 10rem;
    height: 8rem;
}



@media screen and (max-width:768px) {
 

    .hold {

        display: flex;
        text-align: center;
        flex-direction: column;
        width: 50rem;
        height: 15rem;
        border: none;
        font-size: 0.8rem;
        box-shadow: 1px 1px 10px 1px rgb(171, 170, 170);
    }
    
}
.main{
    background-image: linear-gradient(to right,rgba(0, 0, 0, 0.698),rgba(0, 0, 0, 0.678)),url(./../Home/asset/cons6.jpg) ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    
    justify-content: center;
    align-items: center;

}



.main h1{
    text-align: center;
    font-size: 2rem;
    color: white;
    font-family: 'Poppins';
    padding:2rem;
    padding-top:3rem;
    
}

.c{
    display: flex;
    flex-direction:row;
    justify-content: center;
    font-size: 2rem;
    color: white;
    font-family: 'Poppins';
    cursor: pointer;
}

.d
{
    color: white;
    text-decoration: none;
}

@media screen and(max-width:768px) {

    
.main h1{
    text-align: center;
    font-size: 2rem;
    color: white;
    font-family: 'Poppins';
    padding: 2rem;
    padding-top:3rem;
  
    
}

}
.wlc{
    padding:3rem;
    display: flex;
    justify-content: space-around;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),url(./asset/build.jpg);
}

.wl,.wr{
    width: 100%;
    position: relative;
    padding: 0.9rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -5rem;
   
}

.head{

    font-family:"Poppins";
    color: #f17523;
  
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }
  
  
  .heads{
  
    font-family:"Poppins";
    color:#0000FF;
  
    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }


  .row{

display:flex;
justify-content: space-around;
padding:1rem;

  }

  .b1{

    border:1px solid #1f2a4b;
    width: 20rem;
    position: relative;
 margin-left:0.8rem;
 margin-left:0.8rem;
 margin-bottom:0.8rem;
 height:9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    

  }


  .im{

    width: auto;
    position: relative;


  }

    .im2{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}

.img{
    padding:0.6rem;
    height:3rem;
    width:3rem;
  
}


.bh1
{
    text-align: center;
   font-size: 1rem;
   font-family:"Poppins";
    color:blue;
    font-weight: 600;
}

.bh2{
    text-align: center;
    padding: 0.01rem;
    font-size: 1rem;
}


.i{

  height:22rem;
  width:28rem;
 transform: translateY(7.2rem);
  display: flex;
  justify-content: center;
}
.par{
  margin-top: -2rem;
}

.bs{
  display: none;
}

.bbs{
  display: flex;
}
@media screen and (max-width:768px)
{
  .wlc{
    padding:3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0)),url(./asset/build.jpg);
}

.im{
align-items: center;
}

.bs{
  display: flex;
}

.bbs{
  display: none;
}

.im2{
  width: 10rem;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
 
}

.i{

  height:15rem;
  width:15rem;
 transform: translateY(0rem);
  display: flex;
  justify-content: center;
  margin-left: -1rem;
}
.wr{
  margin-top: 2rem;
  margin-left: -1rem;
}
.par{
  text-align: center;
  margin-top: -2rem;
}

.bh1
{
    text-align: center;
   font-size: 0.8rem;
   font-family:"Poppins";
    color:blue;
    font-weight: 600;
    width:auto;
}

.bh2{
    text-align: center;
    width:auto;
    font-size: 1rem;
}

.b1{

  border:1px solid #1f2a4b;
  width: 20rem;
  position: relative;

height:auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  

}

}
.main{
    background-color: rgb(230, 188, 84);
    display: flex;
    width: 100%;
    color: black;
    justify-content: space-between;
    padding: 8px ;
    font-size: .9rem;
    font-weight: 400;
    position: fixed;
    top: 0;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: hidden;
    
   
}
.email {
    margin-left: 6rem;
}

.mobile {
    transform: translateX(-16rem);
}

.tdecor{
    text-decoration: none;
    color: black;
}

.nb{
    color:navy;
}

.or{
    color:rgb(189, 156, 72);
}

.overall{
display: block;
}

.logos{
    display: flex;
    flex-direction: row;
}
.img{

    height: 1rem;
    width: 1rem;
}

   
@media screen and (max-width:768px)
{
  
    .main{
        background-color: rgb(230, 188, 84);
        display: flex;
        flex-direction: column;
        width: 100%;
        color: black;
        justify-content: center;
      padding:0.5rem;
        font-size: 1rem;
        font-weight: 400;
        position: fixed;
       height:2rem;;
        z-index: 999;
        overflow-x: hidden;
        
       
    }
    .email {
        margin-top:1rem;
    }
 
    .mobile {
        transform: translate(2rem, -1rem);
    }
    
    .tdecor{
        text-decoration: none;
        color: black;
        font-size: 0.8rem;;
        padding:0.3rem;
    }
    
    .nb{
        color:navy;
    }
    
    .or{
        color:rgb(189, 156, 72);
    }
    
    .overall{
    display: block;
    }
    
    .logos{
        display: flex;
        flex-direction: row;
    }
    .img{
    
        height: 1rem;
        width: 1rem;
    }
}

.hold{

    background-image: linear-gradient(rgba(0, 0, 0, 0.455),rgba(0, 0, 0, 0.437)),url(./../../Component/Home/asset/build.jpg);
background-attachment: fixed;
background-repeat: no-repeat;
background-size: cover;
}



.container {
    max-width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;


}

.left {
    flex: 1;
    text-align: center;
    margin-top: 1rem;
    margin-right: 1rem;
     background-color: rgba(255, 255, 255, 0.914);
  
}

.leftp{
   
    font-family: "Poppins";
    color:black;
    font-weight:300;
    font-size:0.9rem;
    text-align: justify;
    letter-spacing: 0.08rem;
   line-break: auto;
   margin-right:1rem;
   padding:2rem;
 

}

.right {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.ri {
 width: 25rem;
    height: 25rem;
    display: block;
    margin: 0 auto;
}


.heading{

    font-family: "Poppins";
    color:goldenrod;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
    
      margin-right:1rem;
  }
  
  
  .heads{
  
    font-family: "Poppins";
    color:navy;

    font-weight:bolder;
      text-align: center;
      letter-spacing: 0.2rem;
      font-size: 2rem;
  
  }

  @media  screen and (max-width:768px) {
    
    .container {
        max-width: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    
    
    }

    .left {
        flex: 1;
        text-align: center;
        margin-top: 1rem;
        margin-right: 0rem;
         background-color: rgba(255, 255, 255, 0.914);
      
    }
    
    .leftp{
       
        font-family: "Poppins";
        color:black;
        font-weight:300;
        font-size:0.9rem;
        text-align: justify;
        letter-spacing: 0.08rem;
       line-break: auto;
       margin-right:0rem;
       padding:2rem;
     
    
    }

    .heading{

        font-family: "Poppins";
        color:goldenrod;
    
        font-weight:bolder;
          text-align: center;
          letter-spacing: 0.2rem;
          font-size: 2rem;
        
          margin-right:1rem;
      }

      .ri {
        width: 20rem;
           height: 25rem;
           display: block;
           margin: 0 auto;
       }
       
  }
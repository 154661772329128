.container {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10rem;
    color:  black;
    font-size: 0.9rem;
    font-weight: 300;
    padding-top: 3rem;
    margin-top: 3rem;
    padding-bottom: 3rem;
    flex-wrap: wrap;
    
}
.hold{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hold1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.count {
    background-color:BLACK;
    text-align: center;
   
    font-size: 1rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    
}

.num {
    font-size: 4rem;
    font-weight: 400;
    color: blue;
}

.k {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center; 
    color: black;  
}

@media screen and (max-width:768px) {
  
    .num {
        font-size: 2rem;
        font-weight: 400;
        color: blue;
    }
    
    .k {
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center; 
        color: black;  
    }
.container{
    gap:4rem;
}
}            
.hold {
    margin-top: 4rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("./../Home/asset/build.jpg");
    background-size: cover;
   
}

.mainhead {
    margin-top: 7rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family: 'Poppins';
    font-size: 2rem;
    font-weight: 700;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
    margin-bottom: 6rem;
}

.bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
}

.middle{

    display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 6rem;
      margin-bottom: 6rem;
  }


@media screen and (max-width:768px) {


    .hold {
        margin-top: 2rem;

        padding-top: 4rem;
       
       
    }


    .mainhead {
        margin-top: 9rem;
     
padding:1rem;
        text-align: center;
        font-family: 'Poppins';
        font-size: 2rem;
        font-weight: 700;
    }

    .top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 6rem;
        margin-bottom: 6rem;
    }

    .middle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 6rem;
        margin-bottom: 6rem;
    }


    .bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 6rem;
    }

    


    }
.card {
    width: 14rem;
    border: 1px solid rgb(225, 225, 225);
    padding-top: 1rem;
    border-radius: 0.2rem;
    height:16rem;
    
    margin: 0.2rem;
}

.card:hover {

    border: 1px solid rgb(225, 225, 225);
    padding-top: 1rem;
    border-radius: 0.2rem;
    background-image: linear-gradient(180deg, rgb(205, 238, 250), #ffffc6);
}

.image {
    margin: auto;
    display: block;
    width: 12rem;
    height: 10rem;
    border-radius: 2rem;
}

.txt {
    font-size: 1.1rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}


.ak{
    overflow-x: hidden;
}
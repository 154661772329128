.hold {
    margin-top: -3rem;
padding:3rem;

}

.headhold {
    font-family: 'Poppins';
    display: flex;
    justify-content: center;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
}

.contentHold {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
}

.txtHold {
    width: 35rem;
    text-align: center;
}

.BalaImg {
    width: auto;
    height: 25rem;
}


@media screen and (max-width:768px) {
    .txtHold {
        width: auto;
        text-align: center;
    }
    
    .hold {
        margin-left: 1rem;
   
    }
    .contentHold {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
    }

    .BalaImg {
        width: 15rem;
        height: 15rem;
    }

.imgHold{
    width:auto;
}

}


.main{
    background-image: linear-gradient(to right,rgba(0, 0, 0, 0.698),rgba(192, 185, 185, 0.578)),url(./../cart/shed.jpg) ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}


.main h1{
    text-align: center;
    font-size: 3.3rem;
    color: white;
    font-family: 'Poppine';
    
}